import React, { useEffect } from "react";
import "./App.css";
import { GlobalStyle } from "./styles/globalStyles";
import { Container } from "./styles";
import AppContainer from "./containers";
import { DOWNLOAD } from "./constants";

function App() {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf("android") > -1;
    const isTablet = userAgent.indexOf("mobile") === -1;
    if (isAndroid) {
      window.location.href = DOWNLOAD.android;
    } else if (/iPhone|/i.test(userAgent)) {
      window.location.href = DOWNLOAD.appStore;
    }
  }, []);

  return (
    <Container>
      <GlobalStyle />
      <AppContainer />
    </Container>
  );
}

export default App;
