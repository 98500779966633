export const DOWNLOAD = {
  appStore: "https://apps.apple.com/us/app/chi-app/id1473832026",
  android: "https://play.google.com/store/apps/details?id=com.chi.app",
  posAndroid: "https://play.google.com/store/apps/details?id=com.chi.pos.app",
  posApk: "",
};

export const SOCIAL = {
  twitter: "https://twitter.com/CHINetwork_DAO",
  instagram: "",
  facebook: "",
  linkedIn: "https://www.linkedin.com/company/chinetwork/",
  github: "https://github.com/CHI-Network-DAO",
};

export const BREAK_POINT = {
  TABLET: "768px",
  MOBILE: "576px",
  DESKTOP: "992px",
};
