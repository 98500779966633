import styled from "styled-components";
import { BackgroundSVG } from "./assets/images";

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundSVG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(136.78deg, #00a1e5 -52.84%, #ea5284 68.98%);
  mix-blend-mode: multiply;
  opacity: 0.9;
`;
