import React, { useEffect, useState } from "react";
import {
  Android,
  AppStore,
  DownloadApk,
  SimulatorPC,
  SimulatorMobile,
} from "../../assets/images";
import { BREAK_POINT, DOWNLOAD } from "../../constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import Footer from "../Footer/footer";
import "./styles.scss";

function Main() {
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINT.MOBILE})`);
  const isTablet = useMediaQuery(`(min-width: ${BREAK_POINT.TABLET})`);
  const notPC = useMediaQuery(`(max-width: ${BREAK_POINT.DESKTOP})`);
  const isPC = useMediaQuery(`(min-width: ${BREAK_POINT.DESKTOP})`);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const marginLeft = () => {
    if (isPC) {
      return 100;
    }
    if (isTablet && notPC) {
      return width / 4;
    }
    if (isMobile) return 0;
  };
  return (
    <div className="main">
      <div className="main_left">
        <div className="main_left_title">
          <p>
            {isMobile
              ? `THE CHI APP \nAVAILABLE FOR \nANY DEVICE`
              : "THE CHI APP AVAILABLE \nFOR ANY DEVICE"}
          </p>
        </div>
        <div className="main_left_content">
          <div className="main_left_content-item">
            <div className="item_header">EVENT VISITORS</div>
            <div className="item_title">CHI APP</div>
            <div className="item_description">
              {`Install the mobile app to receive your ticket(s) and for payments at the event.
              
              Do this before arriving at the event to save time at the entrance.`}
            </div>
            <div className="item_btn">
              <a href={DOWNLOAD.appStore} target="_blank" rel="noreferrer">
                <img src={AppStore} alt="download-appStore" />
              </a>
              <a href={DOWNLOAD.android} target="_blank" rel="noreferrer">
                <img src={Android} alt="download-android" />
              </a>
            </div>
          </div>

          <div className="main_left_content-item">
            <div className="item_header">ORGANIZERS</div>
            <div className="item_title">CHI POS APP</div>

            <div className="item_description">
              {`App for point-of-sale tablets at events to facilitate all product and token sales.`}
            </div>

            <div className="item_btn">
              <a href={DOWNLOAD.posAndroid} target="_blank" rel="noreferrer">
                <img src={Android} alt="download-android" />
              </a>
              <a href={DOWNLOAD.posApk} target="_blank" rel="noreferrer">
                <img src={DownloadApk} alt="download-apk" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="main_right" style={{ marginLeft: `${marginLeft()}px` }}>
        <img
          onLoad={({ target: img }) => console.log({ img })}
          src={isMobile ? SimulatorMobile : SimulatorPC}
          alt="simulator"
          height={isMobile ? "530" : "1042"}
          width={isMobile ? "310" : "732"}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Main;
