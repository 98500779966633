import React from "react";
import { BREAK_POINT } from "../constants";
import useMediaQuery from "../hooks/useMediaQuery";
import { Overlay } from "../styles";
import Header from "./Header/header";
import Main from "./Main/main";
import "./styles.scss";

function AppContainer() {
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINT.MOBILE})`);
  return (
    <div className="container">
      {isMobile && <Overlay />}
      <Header />
      <Main />
    </div>
  );
}

export default AppContainer;
