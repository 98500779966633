import React, { useMemo } from "react";
import {
  IcFacebook,
  IcGithub,
  IcInstagram,
  IcLinkedIn,
  IcTwitter,
  IcFacebookMobile,
  IcGithubMobile,
  IcInstagramMobile,
  IcLinkedInMobile,
  IcTwitterMobile,
} from "../../assets/images";
import { BREAK_POINT, SOCIAL } from "../../constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import "./styles.scss";

function Footer() {
  const isTablet = useMediaQuery(`(max-width: ${BREAK_POINT.TABLET})`);

  return (
    <div className="footer">
      <div className="footer_left">
        <p>EVENTCHI, Hofplein 20, 3032 AC, Rotterdam, The Netherlands</p>
        {!isTablet && <div className="footer_left_divider" />}
        <p>KVK: 72745630</p>
      </div>
      <div className="footer_right">
        <a href={SOCIAL.twitter} target="_blank" rel="noreferrer">
          <img
            src={isTablet ? IcTwitterMobile : IcTwitter}
            alt="social-twitter"
          />
        </a>
        <a href={SOCIAL.instagram} target="_blank" rel="noreferrer">
          <img
            src={isTablet ? IcInstagramMobile : IcInstagram}
            alt="social-instagram"
          />
        </a>
        <a href={SOCIAL.facebook} target="_blank" rel="noreferrer">
          <img
            src={isTablet ? IcFacebookMobile : IcFacebook}
            alt="social-facebook"
          />
        </a>
        <a href={SOCIAL.linkedIn} target="_blank" rel="noreferrer">
          <img
            src={isTablet ? IcLinkedInMobile : IcLinkedIn}
            alt="social-linkedIn"
          />
        </a>
        <a href={SOCIAL.github} target="_blank" rel="noreferrer">
          <img src={isTablet ? IcGithubMobile : IcGithub} alt="social-github" />
        </a>
      </div>
      ;
    </div>
  );
}

export default Footer;
