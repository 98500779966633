import { createGlobalStyle } from "styled-components";
import FontLatoChi from "../assets/fonts/LatoCHI-Regular.ttf";

export const GlobalStyle = createGlobalStyle`
  * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
  }
  body {
    font-family: "Lato";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${FontLatoChi}) format('truetype');
    font-style: normal;
  }
`;
