import React from "react";
import { Logo } from "../../assets/images";
import "./styles.scss";

function Header() {
  return (
    <div className="header">
      <img src={Logo} alt="Logo" />
    </div>
  );
}

export default Header;
